var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "WithDraw-wrapper" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.currView,
            callback: function($$v) {
              _vm.currView = $$v
            },
            expression: "currView"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "普通用户", name: "NormalUser" } },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "border-card" },
                  on: { "tab-click": _vm.handleClick2 },
                  model: {
                    value: _vm.currSubView,
                    callback: function($$v) {
                      _vm.currSubView = $$v
                    },
                    expression: "currSubView"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "自动到账", name: "AutoArrive" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 3 } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入客户ID",
                                  clearable: true
                                },
                                model: {
                                  value: _vm.AutoInfo.userId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.AutoInfo, "userId", $$v)
                                  },
                                  expression: "AutoInfo.userId"
                                }
                              })
                            ],
                            1
                          ),
                          _c("el-button", { on: { click: _vm.searchPage } }, [
                            _vm._v("搜索")
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.exportAutoData }
                            },
                            [_vm._v("导出表格")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%", margin: "20px 0" },
                              attrs: { data: _vm.AutoList, border: "" }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "userId",
                                  align: "center",
                                  label: "客户ID"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "nickname",
                                  align: "center",
                                  label: "客户昵称"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "amount",
                                  align: "center",
                                  label: "提现金额"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "提现方式" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        scope.row.type == "withdraw"
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "warning" } },
                                              [_vm._v("提现到账")]
                                            )
                                          : _vm._e(),
                                        scope.row.type == "auto"
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "primary" } },
                                              [_vm._v("自动到账")]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "balance",
                                  align: "center",
                                  label: "账户余额"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "createTime",
                                  align: "center",
                                  label: "提现时间"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { offset: 16 } },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  background: "",
                                  "current-page": _vm.AutoInfo.page,
                                  "page-size": 10,
                                  layout: "total, prev, pager, next, jumper",
                                  total: _vm.AutoTotal
                                },
                                on: {
                                  "current-change": _vm.handleCurrentChange
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "提现到账", name: "CashArrive" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 3 } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入客户ID",
                                  clearable: true
                                },
                                model: {
                                  value: _vm.WithdrawInfo.userId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.WithdrawInfo, "userId", $$v)
                                  },
                                  expression: "WithdrawInfo.userId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 3 } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择审核状态"
                                  },
                                  model: {
                                    value: _vm.WithdrawInfo.status,
                                    callback: function($$v) {
                                      _vm.$set(_vm.WithdrawInfo, "status", $$v)
                                    },
                                    expression: "WithdrawInfo.status"
                                  }
                                },
                                _vm._l(_vm.selectOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 3 } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择客户状态"
                                  },
                                  model: {
                                    value: _vm.WithdrawInfo.userStatus,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.WithdrawInfo,
                                        "userStatus",
                                        $$v
                                      )
                                    },
                                    expression: "WithdrawInfo.userStatus"
                                  }
                                },
                                _vm._l(_vm.errorOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c("el-button", { on: { click: _vm.searchPage2 } }, [
                            _vm._v("搜索")
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.exportWithdrawData }
                            },
                            [_vm._v("导出表格")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.UpdateAllWithdraw(1)
                                }
                              }
                            },
                            [_vm._v("批量审核")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-table",
                            {
                              ref: "multipleTable",
                              staticStyle: { width: "100%", margin: "20px 0" },
                              attrs: { data: _vm.WithdrawList, border: "" },
                              on: {
                                "selection-change": _vm.handleSelectionChange
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  width: "60",
                                  fixed: "left"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "userId",
                                  align: "center",
                                  label: "客户ID"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "nickname",
                                  align: "center",
                                  label: "客户昵称"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "amount",
                                  align: "center",
                                  label: "提现金额"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "提现方式" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        scope.row.type == "withdraw"
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "warning" } },
                                              [_vm._v("提现到账")]
                                            )
                                          : _vm._e(),
                                        scope.row.type == "auto"
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "primary" } },
                                              [_vm._v("自动到账")]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "balance",
                                  align: "center",
                                  label: "账户余额"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "userStatus",
                                  align: "center",
                                  label: "客户状态"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        scope.row.userStatus == "normal"
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "primary" } },
                                              [_vm._v("正常")]
                                            )
                                          : _vm._e(),
                                        scope.row.userStatus == "second"
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "warning" } },
                                              [_vm._v("超出每秒预设的阈值")]
                                            )
                                          : _vm._e(),
                                        scope.row.userStatus == "hour"
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "warning" } },
                                              [_vm._v("超出每小时预设的阈值")]
                                            )
                                          : _vm._e(),
                                        scope.row.userStatus == "day"
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "warning" } },
                                              [_vm._v("超出每天预设的阈值")]
                                            )
                                          : _vm._e(),
                                        scope.row.userStatus == "qcloud"
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "warning" } },
                                              [_vm._v("腾讯云判断异常")]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "createTime",
                                  align: "center",
                                  label: "提现时间"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "操作" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        scope.row.status == "under_review"
                                          ? _c(
                                              "el-tag",
                                              {
                                                attrs: { type: "success" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.confirm(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("通过")]
                                            )
                                          : _vm._e(),
                                        scope.row.status == "under_review"
                                          ? _c(
                                              "el-tag",
                                              {
                                                staticClass: "ml10",
                                                attrs: { type: "danger" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleEdit(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("拒绝")]
                                            )
                                          : _vm._e(),
                                        scope.row.status == "pass"
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "primary" } },
                                              [_vm._v("审核已通过")]
                                            )
                                          : _vm._e(),
                                        scope.row.status == "no_pass"
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "warning" } },
                                              [_vm._v("审核未通过")]
                                            )
                                          : _vm._e(),
                                        scope.row.status == "failure"
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "warning" } },
                                              [_vm._v("提现失败")]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("el-row", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              { on: { click: _vm.chooseAllUser } },
                              [_vm._v("当页全选")]
                            ),
                            _c("el-pagination", {
                              attrs: {
                                background: "",
                                "current-page": _vm.WithdrawInfo.page,
                                "page-size": 10,
                                layout: "total, prev, pager, next, jumper",
                                total: _vm.WithdrawTotal
                              },
                              on: { "current-change": _vm.handleCurrentChange2 }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "导购员", name: "Staff" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入客户ID", clearable: true },
                        model: {
                          value: _vm.KpiInfo.kpi_user_id,
                          callback: function($$v) {
                            _vm.$set(_vm.KpiInfo, "kpi_user_id", $$v)
                          },
                          expression: "KpiInfo.kpi_user_id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入导购员姓名",
                          clearable: true
                        },
                        model: {
                          value: _vm.KpiInfo.name,
                          callback: function($$v) {
                            _vm.$set(_vm.KpiInfo, "name", $$v)
                          },
                          expression: "KpiInfo.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号", clearable: true },
                        model: {
                          value: _vm.KpiInfo.mobile,
                          callback: function($$v) {
                            _vm.$set(_vm.KpiInfo, "mobile", $$v)
                          },
                          expression: "KpiInfo.mobile"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "品牌商名称" },
                          model: {
                            value: _vm.KpiInfo.brandName,
                            callback: function($$v) {
                              _vm.$set(_vm.KpiInfo, "brandName", $$v)
                            },
                            expression: "KpiInfo.brandName"
                          }
                        },
                        _vm._l(_vm.BrandList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择审核状态" },
                          model: {
                            value: _vm.KpiInfo.status,
                            callback: function($$v) {
                              _vm.$set(_vm.KpiInfo, "status", $$v)
                            },
                            expression: "KpiInfo.status"
                          }
                        },
                        _vm._l(_vm.selectOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择导购员状态"
                          },
                          model: {
                            value: _vm.KpiInfo.userStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.KpiInfo, "userStatus", $$v)
                            },
                            expression: "KpiInfo.userStatus"
                          }
                        },
                        _vm._l(_vm.errorOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-button", { on: { click: _vm.searchPage3 } }, [
                    _vm._v("搜索")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.exportKpiData }
                    },
                    [_vm._v("导出表格")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.UpdateAllWithdraw(2)
                        }
                      }
                    },
                    [_vm._v("批量审核")]
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable2",
                      staticStyle: { width: "100%", margin: "20px 0" },
                      attrs: { data: _vm.KpiList, border: "" },
                      on: { "selection-change": _vm.handleSelectionChange2 }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "60", fixed: "left" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "kpiUserId",
                          align: "center",
                          label: "导购员ID"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "kpiName",
                          align: "center",
                          label: "姓名"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "phone",
                          align: "center",
                          label: "手机号"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "cat",
                          align: "center",
                          label: "所属军团"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "brand",
                          align: "center",
                          label: "所属品牌"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "shop",
                          align: "center",
                          label: "所属门店"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "amount",
                          align: "center",
                          label: "提现金额"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "type",
                          align: "center",
                          label: "提现方式"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.type == "withdraw"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [_vm._v("提现到账")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "auto"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("自动到账")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          align: "center",
                          label: "提现时间"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "balance",
                          align: "center",
                          label: "账户余额"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "userStatus",
                          align: "center",
                          label: "导购员状态",
                          width: "200"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.userStatus == "normal"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("正常")]
                                    )
                                  : _vm._e(),
                                scope.row.userStatus == "second"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [_vm._v("超出每秒预设的阈值")]
                                    )
                                  : _vm._e(),
                                scope.row.userStatus == "hour"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [_vm._v("超出每小时预设的阈值")]
                                    )
                                  : _vm._e(),
                                scope.row.userStatus == "day"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [_vm._v("超出每天预设的阈值")]
                                    )
                                  : _vm._e(),
                                scope.row.userStatus == "qcloud"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [_vm._v("腾讯云判断异常")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.status == "under_review"
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: { type: "success" },
                                        on: {
                                          click: function($event) {
                                            return _vm.confirm(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("通过")]
                                    )
                                  : _vm._e(),
                                scope.row.status == "under_review"
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticClass: "ml10",
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleEdit(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("拒绝")]
                                    )
                                  : _vm._e(),
                                scope.row.status == "pass"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("审核已通过")]
                                    )
                                  : _vm._e(),
                                scope.row.status == "no_pass"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [_vm._v("审核未通过")]
                                    )
                                  : _vm._e(),
                                scope.row.status == "failure"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [_vm._v("提现失败")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-row", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between"
                    }
                  },
                  [
                    _c("el-button", { on: { click: _vm.chooseAllUser2 } }, [
                      _vm._v("当页全选")
                    ]),
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "current-page": _vm.KpiInfo.page,
                        "page-size": 10,
                        layout: "total, prev, pager, next, jumper",
                        total: _vm.KpiTotal
                      },
                      on: { "current-change": _vm.handleCurrentChange3 }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "提现规则", name: "TopUpRule" } },
            [
              _c("el-alert", {
                attrs: {
                  title:
                    "提现规则设置后将同步到用户 - 【我的】 - 【我的奖励】 -【提现规则】页",
                  type: "warning",
                  closable: false
                }
              }),
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "提现规则" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 2,
                          placeholder: "请输入内容",
                          resize: "none"
                        },
                        model: {
                          value: _vm.RuleDetail.rule,
                          callback: function($$v) {
                            _vm.$set(_vm.RuleDetail, "rule", $$v)
                          },
                          expression: "RuleDetail.rule"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.UpdateRule }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.auditDialog, width: "80%", title: "批量审核" },
          on: {
            "update:visible": function($event) {
              _vm.auditDialog = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c("el-button", [_vm._v("通过")]),
              _c("el-button", [_vm._v("拒绝")])
            ],
            1
          ),
          _vm.auditDialogType == 1
            ? [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%", margin: "20px 0" },
                        attrs: { data: _vm.tableData, border: "" }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "selection", width: "55" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "id",
                            align: "center",
                            label: "客户ID"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "id",
                            align: "center",
                            label: "客户ID"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            align: "center",
                            label: "客户昵称"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "amount",
                            align: "center",
                            label: "提现金额"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "way",
                            align: "center",
                            label: "提现方式"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "balance",
                            align: "center",
                            label: "账户余额"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "time",
                            align: "center",
                            label: "提现时间"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { type: "flex", justify: "space-between" } },
                  [
                    _c("el-button", [_vm._v("当前页全选")]),
                    _c("el-pagination", {
                      attrs: {
                        total: 400,
                        "current-page": _vm.nodata,
                        layout: "total, sizes, prev, pager, next, jumper"
                      },
                      on: { "current-change": _vm.handleCurrentChange }
                    })
                  ],
                  1
                )
              ]
            : [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%", margin: "20px 0" },
                        attrs: { data: _vm.tableData, border: "" }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "selection", width: "55" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "kpiUserId",
                            align: "center",
                            label: "导购员ID"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            align: "center",
                            label: "姓名"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "phone",
                            align: "center",
                            label: "手机号"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "brand",
                            align: "center",
                            label: "所属军团"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "brand",
                            align: "center",
                            label: "所属品牌"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "shop",
                            align: "center",
                            label: "所属门店"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "time",
                            align: "center",
                            label: "提现金额"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "time",
                            align: "center",
                            label: "提现方式"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "time",
                            align: "center",
                            label: "提现时间"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "time",
                            align: "center",
                            label: "账户余额"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { type: "flex", justify: "space-between" } },
                  [
                    _c("el-button", [_vm._v("当前页全选")]),
                    _c("el-pagination", {
                      attrs: {
                        total: 400,
                        "current-page": _vm.nodata,
                        layout: "total, sizes, prev, pager, next, jumper"
                      },
                      on: { "current-change": _vm.handleCurrentChange }
                    })
                  ],
                  1
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }