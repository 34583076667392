<template>
  <div class="WithDraw-wrapper">
    <el-tabs v-model="currView" type="card" @tab-click="handleClick">
      <el-tab-pane label="普通用户" name="NormalUser">
        <el-tabs v-model="currSubView" type="border-card" @tab-click="handleClick2">
          <el-tab-pane label="自动到账" name="AutoArrive">
            <el-row :gutter="20">
              <el-col :span="3">
                <el-input placeholder="请输入客户ID" :clearable="true" v-model="AutoInfo.userId"></el-input>
              </el-col>
              <el-button @click="searchPage">搜索</el-button>
              <el-button @click="exportAutoData" type="primary">导出表格</el-button>
            </el-row>
            <el-row>
              <el-table :data="AutoList" style="width:100%;margin:20px 0;" border>
                <el-table-column prop="userId" align="center" label="客户ID"></el-table-column>
                <el-table-column prop="nickname" align="center" label="客户昵称"></el-table-column>
                <el-table-column prop="amount" align="center" label="提现金额"></el-table-column>
                <el-table-column align="center" label="提现方式">
                  <template slot-scope="scope">
                    <el-tag type="warning" v-if="scope.row.type=='withdraw'">提现到账</el-tag>
                    <el-tag type="primary" v-if="scope.row.type=='auto'">自动到账</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="balance" align="center" label="账户余额"></el-table-column>
                <el-table-column prop="createTime" align="center" label="提现时间"></el-table-column>
              </el-table>
            </el-row>
            <el-row>
              <el-col :offset="16">
                <el-pagination
                  background
                  @current-change="handleCurrentChange"
                  :current-page="AutoInfo.page"
                  :page-size="10"
                  layout="total, prev, pager, next, jumper"
                  :total="AutoTotal"
                ></el-pagination>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="提现到账" name="CashArrive">
            <el-row :gutter="20">
              <el-col :span="3">
                <el-input placeholder="请输入客户ID" :clearable="true" v-model="WithdrawInfo.userId"></el-input>
              </el-col>
              <el-col :span="3">
                <el-select v-model="WithdrawInfo.status" clearable placeholder="请选择审核状态">
                  <el-option
                    v-for="item in selectOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="3">
                <el-select v-model="WithdrawInfo.userStatus" clearable placeholder="请选择客户状态">
                  <el-option
                    v-for="item in errorOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-col>
              <el-button @click="searchPage2">搜索</el-button>
              <el-button @click="exportWithdrawData" type="primary">导出表格</el-button>
              <el-button @click="UpdateAllWithdraw(1)" type="primary">批量审核</el-button>
            </el-row>
            <el-row>
              <el-table :data="WithdrawList" style="width:100%;margin:20px 0;" border @selection-change="handleSelectionChange" ref="multipleTable">
                <el-table-column type="selection" width="60" fixed="left"></el-table-column>
                <el-table-column prop="userId" align="center" label="客户ID"></el-table-column>
                <el-table-column prop="nickname" align="center" label="客户昵称"></el-table-column>
                <el-table-column prop="amount" align="center" label="提现金额"></el-table-column>
                <el-table-column align="center" label="提现方式">
                  <template slot-scope="scope">
                    <el-tag type="warning" v-if="scope.row.type=='withdraw'">提现到账</el-tag>
                    <el-tag type="primary" v-if="scope.row.type=='auto'">自动到账</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="balance" align="center" label="账户余额"></el-table-column>
                <el-table-column prop="userStatus" align="center" label="客户状态">
                  <template slot-scope="scope">
                    <el-tag type="primary" v-if="scope.row.userStatus=='normal'">正常</el-tag>
                    <el-tag type="warning" v-if="scope.row.userStatus=='second'">超出每秒预设的阈值</el-tag>
                    <el-tag type="warning" v-if="scope.row.userStatus=='hour'">超出每小时预设的阈值</el-tag>
                    <el-tag type="warning" v-if="scope.row.userStatus=='day'">超出每天预设的阈值</el-tag>
                    <el-tag type="warning" v-if="scope.row.userStatus=='qcloud'">腾讯云判断异常</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="createTime" align="center" label="提现时间"></el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <el-tag
                      @click="confirm(scope.row)"
                      v-if="scope.row.status=='under_review'"
                      type="success"
                    >通过</el-tag>
                    <el-tag
                      @click="handleEdit(scope.row)"
                      v-if="scope.row.status=='under_review'"
                      type="danger"
                      class="ml10"
                    >拒绝</el-tag>
                    <el-tag type="primary" v-if="scope.row.status=='pass'">审核已通过</el-tag>
                    <el-tag type="warning" v-if="scope.row.status=='no_pass'">审核未通过</el-tag>
                    <el-tag type="warning" v-if="scope.row.status=='failure'">提现失败</el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <el-row>
              <div style="display:flex;justify-content: space-between;">
                <el-button @click="chooseAllUser">当页全选</el-button>
                <el-pagination
                  background
                  @current-change="handleCurrentChange2"
                  :current-page="WithdrawInfo.page"
                  :page-size="10"
                  layout="total, prev, pager, next, jumper"
                  :total="WithdrawTotal"
                ></el-pagination>
              </div>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane label="导购员" name="Staff">
        <el-row :gutter="20">
          <el-col :span="3">
            <el-input placeholder="请输入客户ID" :clearable="true" v-model="KpiInfo.kpi_user_id"></el-input>
          </el-col>
          <el-col :span="3">
            <el-input placeholder="请输入导购员姓名" :clearable="true" v-model="KpiInfo.name"></el-input>
          </el-col>
          <el-col :span="3">
            <el-input placeholder="请输入手机号" :clearable="true" v-model="KpiInfo.mobile"></el-input>
          </el-col>

          <el-col :span="3">
            <el-select v-model="KpiInfo.brandName" placeholder="品牌商名称">
              <el-option
                v-for="item in BrandList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-select v-model="KpiInfo.status" placeholder="请选择审核状态">
              <el-option
                v-for="item in selectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-select v-model="KpiInfo.userStatus" clearable placeholder="请选择导购员状态">
              <el-option
                v-for="item in errorOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-button @click="searchPage3">搜索</el-button>
          <el-button @click="exportKpiData" type="primary">导出表格</el-button>
          <el-button @click="UpdateAllWithdraw(2)" type="primary">批量审核</el-button>
        </el-row>
        <el-row>
          <el-table :data="KpiList" style="width:100%;margin:20px 0;" border @selection-change="handleSelectionChange2" ref="multipleTable2">
            <el-table-column type="selection" width="60" fixed="left"></el-table-column>
            <el-table-column prop="kpiUserId" align="center" label="导购员ID"></el-table-column>
            <el-table-column prop="kpiName" align="center" label="姓名"></el-table-column>
            <el-table-column prop="phone" align="center" label="手机号"></el-table-column>
            <el-table-column prop="cat" align="center" label="所属军团"></el-table-column>
            <el-table-column prop="brand" align="center" label="所属品牌"></el-table-column>
            <el-table-column prop="shop" align="center" label="所属门店"></el-table-column>
            <el-table-column prop="amount" align="center" label="提现金额"></el-table-column>

            <el-table-column prop="type" align="center" label="提现方式">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.type=='withdraw'">提现到账</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='auto'">自动到账</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" align="center" label="提现时间"></el-table-column>
            <el-table-column prop="balance" align="center" label="账户余额"></el-table-column>
            <el-table-column prop="userStatus" align="center" label="导购员状态"  width="200">
              <template slot-scope="scope">
                <el-tag type="primary" v-if="scope.row.userStatus=='normal'">正常</el-tag>
                <el-tag type="warning" v-if="scope.row.userStatus=='second'">超出每秒预设的阈值</el-tag>
                <el-tag type="warning" v-if="scope.row.userStatus=='hour'">超出每小时预设的阈值</el-tag>
                <el-tag type="warning" v-if="scope.row.userStatus=='day'">超出每天预设的阈值</el-tag>
                <el-tag type="warning" v-if="scope.row.userStatus=='qcloud'">腾讯云判断异常</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-tag
                  @click="confirm(scope.row)"
                  v-if="scope.row.status=='under_review'"
                  type="success"
                >通过</el-tag>

                <el-tag
                  @click="handleEdit(scope.row)"
                  type="danger"
                  v-if="scope.row.status=='under_review'"
                  class="ml10"
                >拒绝</el-tag>
                <el-tag type="primary" v-if="scope.row.status=='pass'">审核已通过</el-tag>
                <el-tag type="warning" v-if="scope.row.status=='no_pass'">审核未通过</el-tag>
                <el-tag type="warning" v-if="scope.row.status=='failure'">提现失败</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <div style="display:flex;justify-content: space-between;">
            <el-button @click="chooseAllUser2">当页全选</el-button>
            <el-pagination
              background
              @current-change="handleCurrentChange3"
              :current-page="KpiInfo.page"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
              :total="KpiTotal"
            ></el-pagination>
          </div>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="提现规则" name="TopUpRule">
        <el-alert title="提现规则设置后将同步到用户 - 【我的】 - 【我的奖励】 -【提现规则】页" type="warning" :closable="false"></el-alert>
        <el-form ref="form" :model="form">
          <el-form-item label="提现规则">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="RuleDetail.rule"
              resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="UpdateRule">保存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <el-dialog :visible.sync="auditDialog" width="80%" title="批量审核">
      <el-row>
        <el-button>通过</el-button>
        <el-button>拒绝</el-button>
      </el-row>
      <template v-if="auditDialogType==1">
        <el-row>
          <el-table :data="tableData" style="width:100%;margin:20px 0;" border>
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="id" align="center" label="客户ID"></el-table-column>

            <el-table-column prop="id" align="center" label="客户ID"></el-table-column>
            <el-table-column prop="name" align="center" label="客户昵称"></el-table-column>
            <el-table-column prop="amount" align="center" label="提现金额"></el-table-column>
            <el-table-column prop="way" align="center" label="提现方式"></el-table-column>
            <el-table-column prop="balance" align="center" label="账户余额"></el-table-column>
            <el-table-column prop="time" align="center" label="提现时间"></el-table-column>
          </el-table>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-button>当前页全选</el-button>

          <el-pagination
            :total="400"
            :current-page="nodata"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </el-row>
      </template>

      <template v-else>
        <el-row>
          <el-table :data="tableData" style="width:100%;margin:20px 0;" border>
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="kpiUserId" align="center" label="导购员ID"></el-table-column>
            <el-table-column prop="name" align="center" label="姓名"></el-table-column>
            <el-table-column prop="phone" align="center" label="手机号"></el-table-column>
            <el-table-column prop="brand" align="center" label="所属军团"></el-table-column>
            <el-table-column prop="brand" align="center" label="所属品牌"></el-table-column>
            <el-table-column prop="shop" align="center" label="所属门店"></el-table-column>
            <el-table-column prop="time" align="center" label="提现金额"></el-table-column>
            <el-table-column prop="time" align="center" label="提现方式"></el-table-column>
            <el-table-column prop="time" align="center" label="提现时间"></el-table-column>
            <el-table-column prop="time" align="center" label="账户余额"></el-table-column>
          </el-table>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-button>当前页全选</el-button>

          <el-pagination
            :total="400"
            :current-page="nodata"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </el-row>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import FinanceAjax from "@/utils/https/modules/Finance.request.js";
import { getSession } from "@/utils/utils.js";
export default {
  name: "WithDraw",
  props: {
    activityID: String,
  },
  data() {
    return {
      nodata: "",
      form: {},
      errorOptions: [
        { value: "normal", label: "正常" },
        { value: "second", label: "超出每秒预设的阈值" },
        { value: "hour", label: "超出每小时预设的阈值" },
        { value: "day", label: "超出每天预设的阈值" },
        { value: "qcloud", label: "腾讯云判断异常" },
      ],
      selectOptions: [
        { value: "pass", label: "已通过" },
        { value: "no_pass", label: "未通过" },
        { value: "under_review", label: "未处理" },
      ],
      currView: "NormalUser",
      currSubView: "AutoArrive",
      auditDialog: false,
      auditDialogType: 2,
      tableData: [
        {
          id: 1,
          name: 1,
          amount: 1,
          way: 1,
          time: 1,
          balance: 1,
        },
      ],
      // 提现查询参数
      AutoList: [],
      AutoTotal: 0,
      AutoInfo: {
        actId: this.activityID,
        userId: "",
        page: 1,
        pageSize: 10,
      },
      BrandList: [],
      WithdrawList: [],
      WithdrawTotal: 0,
      WithdrawInfo: {
        actId: this.activityID,
        userStatus: "",
        page: 1,
        size: 10,
        status: "",
        userId: "",
      },
      KpiList: [],
      KpiTotal: 0,
      KpiInfo: {
        actId: this.activityID,
        brandName: "",
        kpi_user_id: "",
        mobile: "",
        name: "",
        page: 1,
        size: 10,
        status: "",
        userStatus: "",
      },
      RuleDetail: {
        actId: this.activityID,
        rule: "",
      },
      KpiUserId: 0,
      multipleSelection:[],//当页多选数组（普通用户
      multipleSelection2:[],//当页多选数组（导购员
    };
  },
  methods: {
    //切换tab方法
    handleClick() {
      if (this.currView == "NormalUser") {
        this.AutoInfo.page = 1;
        this.getWithdrawUserAutoArrived();
        this.currSubView = "AutoArrive";
      } else if (this.currView == "Staff") {
        this.KpiInfo.page = 1;
        this.getWithdrawKpiWithdrawList();
      } else if (this.currView == "TopUpRule") {
        this.getDetail();
      }
    },
    handleClick2() {
      if (this.currSubView == "AutoArrive") {
        this.AutoInfo.page = 1;
        this.getWithdrawUserAutoArrived();
      } else if (this.currSubView == "CashArrive") {
        this.WithdrawInfo.page = 1;
        this.getWithdrawUserWithdrawList();
      }
    },
    confirm(row) {
      this.$confirm("确认同意该用户提现操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.UpdateWithdraw(row, "pass");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async UpdateWithdraw(row, type) {
      let res = await FinanceAjax.UpdateWithdraw({
        list: [row.id],
        status: type,
      });
      this.getWithdrawUserWithdrawList();
      this.getWithdrawKpiWithdrawList();
      this.$message.success("审核操作成功");
    },
    handleEdit(row) {
      this.$confirm("确认拒绝该用户提现操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.UpdateWithdraw(row, "no_pass");
        })
        .catch(() => {});
    },
    handleCurrentChange(val) {
      this.AutoInfo.page = val;
      this.getWithdrawUserAutoArrived();
    },
    // 11.19批量审核拒绝
    // 多选方法
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange2(val) {
      this.multipleSelection2 = val;
    },
    // 当页全选(普通用户)
    chooseAllUser(){
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleAllSelection();
    },
    // 当页全选（导购员）
    chooseAllUser2(){
      this.$refs.multipleTable2.clearSelection();
      this.$refs.multipleTable2.toggleAllSelection();
    },
    async UpdateAllWithdraw(type){
      this.$confirm("确认批量审核用户提现操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          var targetArray = [];
          var noallow = false;
          var targettable = [];
          if (type==1) {
            targettable = this.multipleSelection
          }else if(type==2){
            targettable = this.multipleSelection2
          }else{
            return;
          }
          if(targettable.length==0){
            this.$message.warning("请先选择需要审核的数据");
            return;
          }
          targettable.forEach(item=>{
            if (item.status!="under_review") {
              noallow = true;
            }else if(item.status=="under_review"){
              targetArray.push(item.id)
            }
          })
          if (noallow) {
            this.$message.warning("已选数据中包含已审核数据，请选择未审核的数据进行批量操作");
            return;
          }
          let res = await FinanceAjax.UpdateWithdraw({
            list: targetArray,
            status: "pass",
          });
          this.getWithdrawUserWithdrawList();
          this.getWithdrawKpiWithdrawList();
          this.$message.success("批量审核操作成功");
        })
        .catch(() => {});
    },
    searchPage() {
      this.AutoInfo.page = 1;
      this.getWithdrawUserAutoArrived();
    },
    handleCurrentChange2(val) {
      this.WithdrawInfo.page = val;
      this.getWithdrawUserWithdrawList();
    },
    searchPage2() {
      this.WithdrawInfo.page = 1;
      this.getWithdrawUserWithdrawList();
    },
    handleCurrentChange3(val) {
      this.KpiInfo.page = val;
      this.getWithdrawKpiWithdrawList();
    },
    searchPage3() {
      this.KpiInfo.page = 1;
      this.getWithdrawKpiWithdrawList();
    },
    // 提现---客户自动到账查询
    async getWithdrawUserAutoArrived() {
      try {
        this.AutoInfo.actId = Number(this.activityID);
        var data = await FinanceAjax.getWithdrawUserAutoArrived(this.AutoInfo);
        if (data.data) {
          this.AutoList = data.data.list;
          this.AutoTotal = data.data.total;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出客户自动到账查询
    async exportAutoData() {
      try {
        const data = await FinanceAjax.ExportWithdrawUserAutoArrived({
          actId: this.AutoInfo.actId,
          userId: this.AutoInfo.userId,
        });
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "客户自动到账记录.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 查询品牌商列表
    async getActivityBrandList() {
      try {
        const data = await ActivityAjax.getActivityBrandList({
          actId: this.activityID,
          catId: "",
          name: "",
          page: 1,
          size: 1000,
        });
        if (data.data) {
          this.BrandList = data.data.list;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 提现---客户提现到账查询
    async getWithdrawUserWithdrawList() {
      try {
        this.WithdrawInfo.actId = this.activityID;
        var data = await FinanceAjax.getWithdrawUserWithdrawList(
          this.WithdrawInfo
        );
        if (data.data) {
          this.WithdrawList = data.data.list;
          this.WithdrawTotal = data.data.total;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出客户提现到账查询
    async exportWithdrawData() {
      try {
        const data = await FinanceAjax.ExportWithdrawUserWithdrawList(
          this.WithdrawInfo
        );
        console.log(data);
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "客户提现到账记录.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 提现---导购员提现查询
    async getWithdrawKpiWithdrawList() {
      try {
        this.KpiInfo.actId = this.activityID;
        var data = await FinanceAjax.getWithdrawKpiWithdrawList(this.KpiInfo);
        if (data.data) {
          this.KpiList = data.data.list;
          this.KpiTotal = data.data.total;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出导购员提现查询
    async exportKpiData() {
      try {
        const data = await FinanceAjax.ExportWithdrawKpiWithdrawList(
          this.KpiInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "导购员提现到账记录.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    async getDetail() {
      try {
        var detail = (await FinanceAjax.getConfigQueryByActId(this.activityID))
          .data;
        this.RuleDetail = {
          actId: this.activityID,
          id: detail.id,
          rule: detail.withdrawalRule,
        };
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 更新提现规则
    async UpdateRule() {
      this.RuleDetail.actId = Number(this.activityID);
      let act = [Number(this.activityID), this.RuleDetail.rule];
      console.log(act);
      await FinanceAjax.UpdateRule(act);
      this.$message.success("编辑提现规则成功");
      await this.getDetail();
    },
  },
  created() {
    this.getWithdrawUserAutoArrived();
    this.getWithdrawUserWithdrawList();
    this.getWithdrawKpiWithdrawList();
    this.getDetail();
    this.getActivityBrandList();
    var kpiuserId = getSession("admindetail");
    console.log(kpiuserId);
    this.KpiUserId = kpiuserId.id;
  },
};
</script>

<style lang="scss" scoped>
.WithDraw-wrapper {
}
</style>