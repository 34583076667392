import {
  post,
  put,
} from "../Http";

export default {

  // 总收入--员工充值总额
  getActacountQueryKpiUserSum: params =>
    post(`/admin/v1/finance/actAcount/queryKpiUserSum?actId=${params}`, {}, false),
  // 总收入---品牌充值总数
  getChargeBrandChargeSum: params =>
    post(`/admin/v1/finance/charge/brandChargeSum?actId=${params}`, {}, false),
  // 总收入---财务充值
  getChargeFinanceChargeSum: params =>
    post(`/admin/v1/finance/charge/financeChargeSum?actId=${params}`, {}, false),
  // 总收入---订单交易金额
  getOrderQuerySum: params =>
    post(`/admin/v1/finance/order/querySum?actId=${params}`, {}, false),
  // 总支出--预热页推广奖励
  getBonusrecordQueryShareSum: params =>
    post(`/admin/v1/finance/bonusrecord/queryShareSum?actId=${params}`, {}, false),
  // 总支出--员工单项奖励金
  getActacountQuerySingleSum: params =>
    post(`/admin/v1/finance/actAcount/querySingleSum?actId=${params}`, {}, false),
  // 总支出--员工奖励豆总额
  getActacountQueryBeanSum: params =>
    post(`/admin/v1/finance/actAcount/queryBeanSum?actId=${params}`, {}, false),

  // 活动费用系数查询
  getConfigQueryByActId: params =>
    post(`/admin/v1/finance/config/queryByActId?actId=${params}`),
  // 活动费用 --浏览量
  getUsertagQueryCount: params =>
    post(`/admin/v1/finance/usertag/queryCount?actId=${params}`, {}, false),
  // 活动费用 --秒杀总额查询
  getOrderQuerySeckillSum: params =>
    post(`/admin/v1/finance/order/querySeckillSum?actId=${params}`, {}, false),
  // 活动费用--直播总额查询
  getOrderQueryLiveSum: params =>
    post(`/admin/v1/finance/order/queryLiveSum?actId=${params}`, {}, false),
  // 活动费用--售卡
  getCardrecordQueryCount: params =>
    post(`/admin/v1/finance/cardrecord/queryCount?actId=${params}`, {}, false),
  // 活动费用--直播订单数
  getOrderQueryLiveCount: params =>
    post(`/admin/v1/finance/order/queryLiveCount?actId=${params}`, {}, false),

  // 活动费用系数及红包、短信更新 
  updateFinanceConfig: params =>
    post(`/admin/v1/finance/config/update`, params),


  // 秒杀商品列表
  getSeckillProductList: params =>
    post(`/admin/v1/finance/bonusrecord/queryShareSum`, params),

  // 充值费用设置接口-获取数据（客户自充）
  getChargeConfig: params =>
    post(`/admin/v1/finance/chargeconfig/get?actId=${params}`),
  // 充值费用设置接口-获取数据（活动方自充）
  getChargeFinanceChargeSingleSum: params =>
    post(`/admin/v1/finance/charge/financeChargeSingleSum?actId=${params}`),
  // 客户自充设置需要充值的数额
  saveChargeConfig: params =>
    post(`/admin/v1/finance/chargeconfig/saveOrUpdate`, params),
  // 活动方设置需要充值的品牌数额
  saveBrandChargeConfig: params =>
    post(`/admin/v1/finance/charge/brandCharge`, params),
  // 活动方设置需要充值的员工数额
  saveKpiUserCharge: params =>
    post(`/admin/v1/finance/charge/kpiUserCharge`, params),


  // 充值记录---员工充值记录查询
  getChargeKpiUserChargeRecord: params =>
    post(`/admin/v1/finance/charge/kpiUserChargeRecord`, params),
  // 充值记录---导出员工充值记录查询
  ExportChargeKpiUserChargeRecord: params =>
    post(`/admin/v1/finance/charge/kpiUserChargeRecordDownload`, params),
  // 充值记录---品牌负责人充值记录查询
  getChargeBrandChargeRecord: params =>
    post(`/admin/v1/finance/charge/brandChargeRecord`, params),
  // 充值记录---导出品牌负责人充值记录查询
  ExportChargeBrandChargeRecord: params =>
    post(`/admin/v1/finance/charge/brandChargeRecordDownload`, params),
  // 充值记录---财务充值记录查询
  getChargeFinanceList: params =>
    post(`/admin/v1/finance/charge/financeList`, params),
  // 充值记录---导出财务充值记录查询
  ExportChargeFinanceList: params =>
    post(`/admin/v1/finance/charge/financeListDownload`, params),

  // 提现---客户自动到账查询，余额字段未定义
  getWithdrawUserAutoArrived: params =>
    post(`/admin/v1/finance/withdraw/userAutoArrived`, params),
  // 提现---导出客户自动到账查询
  ExportWithdrawUserAutoArrived: params =>
    post(`/admin/v1/finance/withdraw/userAutoArrivedDownload`, params),
  // 提现---客户提现到账查询
  getWithdrawUserWithdrawList: params =>
    post(`/admin/v1/finance/withdraw/userWithdrawList?pageNum=${params.page}&pageSize=${params.size}`, params),
  // 提现---导出客户提现到账查询
  ExportWithdrawUserWithdrawList: params =>
    post(`/admin/v1/finance/withdraw/userWithdrawListDownload`, params),
  // 提现---员工提现查询
  getWithdrawKpiWithdrawList: params =>
    post(`/admin/v1/finance/withdraw/kpiWithdrawList?pageNum=${params.page}&pageSize=${params.size}`, params),
  // 提现---导出员工提现查询
  ExportWithdrawKpiWithdrawList: params =>
    post(`/admin/v1/finance/withdraw/kpiWithdrawListDownload`, params),
  // 提现---添加提现规则
  UpdateRule: params =>
    post(`/admin/v1/finance/config/updateRule?actId=${params[0]}&rule=${params[1]}`),
  // 提现---批量提现通过或拒绝
  UpdateWithdraw: params =>
    post(`/admin/v1/finance/withdraw/updateWithdrawList?status=${params.status}`, params.list),
}
